import { Col, Container, Row, Image } from "react-bootstrap";
import "./Home.css";

export default function Home() {
  return (
    <>
      <Container className="App" style={{ marginBottom: 15 }}>
        <Row style={{ marginTop: 25 }}>
          <Col xs={12} md={{ span: 4, offset: 4 }} className="d-flex justify-content-center">
            <Image style={{width: "100%", maxWidth: "250px"}} src="/assets/LGFM Logo.png" />
          </Col>
        </Row>
        <Row style={{ marginTop: 80 }} className="d-flex align-items-center">
          <Col xs={1} md={3}></Col>
          <Col xs={10} md={6} className="d-flex justify-content-center">
            <a className="grow ClicJe" href="https://www.just-eat.es/a-domicilio/cadenas/dani-garcia" rel="noreferrer noopener" target="_blank">
              <Row>
                <Col xs={12}>
                  <Image className="JeImg" style={{ width: "100px", marginBottom: 25 }} src="/assets/Boton JE.png" />
                </Col>
                <Col xs={12} className="textoAgregador JeTexto">
                  Just Eat
                </Col>
              </Row>
            </a>
          </Col>
          <Col xs={1} md={3}></Col>
        </Row>
        <Row className="justify-content-center mt-5"></Row>
      </Container>
      <div className="d-flex align-items-center mt-5">
        <Col xs={12} className="textblanco" style={{textAlign: "center", fontSize: 15}}>
          <Image src="/assets/Dani_Garcia_Logo.png" style={{width: '120px', marginLeft: '10px'}} /><br />
          <a href="/politica-de-privacidad" style={{color: "#fff"}}>Política de privacidad</a><br />
          <div style={{marginTop: "5px"}}>
          © 2022 Grupo Dani García
          </div>
        </Col>
      </div>
    </>
  );
}
